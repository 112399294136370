import React, { useEffect } from "react";

const RageHook = () => {
  useEffect(() => {
    window.addEventListener("fullstory/rageclick", function (e) {
      console.log("Rage Click on my site test!", e);

      return () => {
        window.removeEventListener("fullstory/rageclick", e);
      };
    });
  }, []);

  return <div></div>;
};

export default RageHook;
