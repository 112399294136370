import React from "react";
import { CardGroup, Container, Row } from "react-bootstrap";
import ProductCard from "../components/ProductCard";

const rowStyle={
  marginLeft: 'unset',
  marginRight: 'unset'
}

const ProductCards = (props) => {
  return (
    <Container>
      <Row lg={12} style={rowStyle}>
        <CardGroup>
          {props.items.map((item, key) => {
            return (
              <ProductCard key={key} item={item} />
            );
          })}
        </CardGroup>
      </Row>
    </Container>
  );
};

export default ProductCards;
