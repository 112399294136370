import React, { useEffect, useState } from "react";
import { Jumbotron } from "react-bootstrap";

const JumboBanner = (props) => {
  let styles = props === null ? "" : props.type;
  const type = props === null ? "container" : props.type;
  let burl = props === null ? "" : props.bannerUrl;
  let title = props === null ? "Hello, everyone!" : props.title;
  let message = props === null ? "This is my collection" : props.message;
  
  const bStyle = {
    objectFit: "cover",
    width: "100%" 
  };

  function simulateNetworkRequest() {
    return new Promise((resolve) => setTimeout(resolve, 2000));
  }
  
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if (isLoading) {
      simulateNetworkRequest().then(() => {
        setLoading(false);
      });
    }
  }, [isLoading]);

  

  const ShowJumboTron = () => {
    return (
      <Jumbotron
        className={styles}
        style={{ padding: "none !important", margin: "none" }}
      >
        {!burl ? "" : <img style={bStyle} alt="img" src={burl} />}
        <h1>{title}</h1>
        <p>{message}</p>
       
      </Jumbotron>
    );
  };

  const ShowContainer = () => {
    return (
      <div className="container">
        {!burl ? "" : <img style={bStyle} alt="img" src={burl} />}
       
      </div>
    );
  };

  return <>{type ? <ShowJumboTron /> : <ShowContainer />}</>;
};

export default JumboBanner;
