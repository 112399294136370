import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useContext, useState, useEffect } from "react";
import { Auth } from "aws-amplify";

import NavBar from "./components/NavBar";
import About from "./layouts/components/About";
import NotFound from "./layouts/components/NotFound";
import Login from "./layouts/components/Login";
import Home from "./layouts/Home";
import Category from "./layouts/Category";
import Dashboard from "./layouts/Dashboard";
import Product from "./layouts/Product";
import RageHook from "./utils/RageHook";
import SignUp from "./layouts/components/SignUp";
import { AppContext } from "./utils/ContextLib";
import { CartProvider } from "./utils/CartContext";
import "./App.scss";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const [cart, setCart] = useState();

  useEffect(() => {
    onLoad();
  });

  async function onLoad() {
    try {
      await Auth.currentSession();
      userHasAuthenticated(true);
    } catch (e) {
      if (e !== " No current user") {
        console.log(e);
      }
    }

    setIsAuthenticating(false);
  }

  return (
    !isAuthenticating && (
      <>
        <AppContext.Provider value={{ isAuthenticated, userHasAuthenticated }}>
          <RageHook />
          <CartProvider value={{ cart, setCart }}>
            <Router>
              <NavBar />
              <Routes>
                <Route path="/" element={< Home />} />
                <Route path="/category/:categoryid" element={< Category />} />
                <Route path="/about" element={< About />} />
                <Route path="signup" element={< SignUp />} />
                <Route path="/login" element={< Login />} />
                <Route path="/dashboard" element={< Dashboard />} />
                <Route path="/product/:productid/*" element={< Product />} />
                <Route path="*" element={< NotFound />} />;
              </Routes>
            </Router>
          </CartProvider>
        </AppContext.Provider>
      </>
    )
  );
}

export default App;
