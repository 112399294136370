import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Sidebar from "./components/Sidebar";
import AdminNavbar from "./components/Navbars/AdminNavbar";

const styles = {
  adminCard: {
    minHeight: "140px",
    paddingBottom: "10px",
    marginBottom: "10px",
  },
};
const Dashboard = () => {
  useEffect(() => {
    window.analytics.page("admin", "Dashboard");
  });
  return (
    <Container fluid>
      <AdminNavbar />
      <Row>
        <Col xs={2} id="sidebar-wrapper">
          <Sidebar />
        </Col>
        <Col xs={10} id="page-content-wrapper">
          <Container>
            <Row lg={12}>
              <Col md={12}>
                <div className="card" style={styles.adminCard}></div>
              </Col>
            </Row>
            <Row lg={12}>
              <Col md={4}>
                <div className="card" style={styles.adminCard}></div>
              </Col>
              <Col md={4}>
                <div className="card" style={styles.adminCard}></div>
              </Col>
              <Col md={4}>
                <div className="card" style={styles.adminCard}></div>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>
  );
};

export default Dashboard;
