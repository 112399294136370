
import React, { useState } from "react";
import { Navbar, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Auth } from 'aws-amplify';
import { useAppContext } from "../utils/ContextLib";
import Cart from "../components/Cart"

const logoStyle = {
  logoImage: {
    marginRight: "10px",
  },
  cartImage: {
    marginLeft: "10px"
  },
  loginForm: {
     display: "flex"
  }

}

const logoPurple = {
  color: "#9813FD",
  fontWeight: "700",
};

function NavBar() {

  const { isAuthenticated, userHasAuthenticated } = useAppContext();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  async function signOut() {
    try {
      console.log('Calling logout', isAuthenticated)
      await Auth.signOut();
      userHasAuthenticated(false)

    } catch (error) {
      console.log('error signing out: ', error);
    }
  }


  return (
    <div className="login">
      <Cart show={show} handleClose={handleClose} />

      <Navbar
        collapseOnSelect
        expand="lg"
        bg="dark"
        variant="dark"
        sticky="top"
      >
        <Navbar.Brand href="/">
          <img
            src="https://merch-bucket.s3.amazonaws.com/media/shimmy-logo-small.png"
            width="30"
            height="30"
            className="d-inline-block align-top"
            alt="Shimmy logo"
            style={logoStyle.logoImage}
          />
          <span style={logoPurple}>Shimmy</span>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav activeKey={window.location.pathname} className="mr-auto">
            <Link to="/about">About</Link>
          </Nav>
          <Nav>
            <div style={logoStyle.loginForm}>
              {isAuthenticated !== false ? (
                <Link to='/' onClick={signOut} >Logout</Link>
              ) : (
                <>
                  <Nav style={logoStyle.logoImage}>
                    <Link to="/signup">Signup</Link>
                  </Nav>
                  <Nav to="/login">
                    <Link to="/login">Login</Link>
                  </Nav>
                </>
              )}
            </div>
          </Nav>
          <Nav style={logoStyle.cartImage}>
            <Link to="" onClick={handleShow}>
              <i className="bi bi-cart"></i>
            </Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
}

export default NavBar;
