import { useContext } from "react"
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Image from 'react-bootstrap/Image';
import { Container, Col, Row } from "react-bootstrap";


import { CartContext } from "../utils/CartContext"

const Cart = ({ show, handleClose}) => {
    const { cartItems, addToCart, removeFromCart, clearCart, getCartTotal } = useContext(CartContext)
    return (


        <Modal className="cart-modal" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Cart</Modal.Title>
        </Modal.Header>
        <Modal.Body>Items in your cart</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer>
        <Container>
            <h2 className="text-2xl font-bold">Items</h2>
            
                {cartItems.map((item) => (
                    <Row key={item.pid}>
                        
                        <Col xs={6}>
                            <Image src={item.url} alt={item.title} thumbnail />
                            <h6>{item.title}</h6>
                        </Col>
                        <Col xs={2}>
                        <span>
                                
                                <p className="text-gray-600">{item.price}</p>
                                </span>
                        </Col>
                        <Col xs={4}>
                          <Container>
                            <Row className="justify-content-between">
                            <button
                                className="px-3 py-1 bg-gray-800 text-black text-xs font-bold uppercase rounded hover:bg-gray-700 focus:outline-none focus:bg-gray-700"
                                onClick={() => {
                                    addToCart(item)
                                }}
                            >
                                +
                            </button>
                            <p className="px-2 py-2">{item.quantity}</p>
                            <button
                                className="px-3 py-1 bg-gray-800 text-black text-xs font-bold uppercase rounded hover:bg-gray-700 focus:outline-none focus:bg-gray-700"
                                onClick={() => {
                                    removeFromCart(item)
                                }}
                            >
                                -
                            </button>
                            </Row>
                            </Container>
                            </Col>   
                        
                        
                    </Row>
                ))}
            
            {
                cartItems.length > 0 ? (
                    <div className="py-2">
                    <div className="flex flex-col justify-between items-center">
                        <h3 className="text-lg font-bold">Total: ${getCartTotal()}</h3>
                        <button
                            className="px-4 py-2 bg-black-800 text-black text-xs font-bold uppercase rounded hover:bg-gray-700 focus:outline-none focus:bg-gray-700"
                            onClick={() => {
                                clearCart()
                            }}
                        >
                            Clear cart
                        </button>
                    </div>
                    </div>
                ) : (
                    <h1 className="text-lg font-bold">Your cart is empty</h1>
                )
            }
        </Container>
      </Modal>
     

    )
}

export default Cart;

