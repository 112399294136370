import React, { useContext, useState } from "react";
import { useLocation } from "react-router-dom";
import { Container, Button, Row, Col } from "react-bootstrap";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS } from "@contentful/rich-text-types";
import { FullStoryAPI } from "react-fullstory";
import { CartContext } from "../utils/CartContext";
import Toast from "react-bootstrap/Toast";


const styles = {
  imageStyle: {
    width: "100%",
  },
  cardStyle: {
    marginTop: "25px",
    marginRight: "10px",
    width: "100%",
  },
};

const Product = () => {
  const location = useLocation();
  const prodData = location.state;
  const { addToCart } = useContext(CartContext);
  const [show, setShow] = useState(false);

  const handleClick = (e) => {
    e.preventDefault();
    const number = 60;
    // do stuff
    FullStoryAPI("event", "Add to Cart", {
      uid_str: "750948353",
      pid: prodData.title,
    });
    setShow(true);
    console.log("add to cart");
    try {
      if (number > 50) {
        window.analytics.track("Add to Cart", {
          list_id: "Featured Product",
          product: prodData.slug,
        });
        addToCart(prodData);
      } else {
        // user-defined throw statement
        window.onerror = function(message, file, line, col, error) {
          alert("Error occurred: " + error.message);
          return false;
        };
      }
    } catch (error) {
      console.log("An error caught");
      console.log("Error message: " + error);
    }
  };

  const renderDocument = (document) => {
    const Text = ({ children }) => <p>{children}</p>;

    const options = {
      renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
      },
      renderText: (text) =>
        text.split("\n").flatMap((text, i) => [i > 0 && <br />, text]),
    };

    return documentToReactComponents(document, options);
  };

  return (
    <Container>
      <Row>
        <Col>
        
          <Toast
            onClose={() => setShow(false)}
            show={show}
            delay={3000}
            autohide
          >
            <Toast.Header>
              <img
                src="holder.js/40x40?text=%40"
                className="rounded me-2"
                alt=""
              />
              <strong className="me-auto">Added to cart &nbsp; </strong>
              <small> less than 1 mins ago</small>
            </Toast.Header>
            <Toast.Body>
                {prodData.title}
            </Toast.Body>
          </Toast>
          
        </Col>       
      </Row>
      <Col>
        <Row>
          <div className="col-lg-4 p-3">
            <img
              alt={prodData.title}
              src={prodData.url}
              style={styles.imageStyle}
            ></img>
          </div>

          <div className="col-lg-8 article">
            <span className="title">{prodData.title}</span>
            <p>{prodData.subTitle}</p>
            <article>{renderDocument(prodData.body)}</article>
            <Button variant="secondary" size="lg" onClick={handleClick}>
              Add to Cart
            </Button>
          </div>
        </Row>
      </Col>
    </Container>
  );
};

export default Product;
