import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import CategoryCard from './CategoryCard';


function CategoryCards (props) {
    return (
        <Container>
          <Row lg={12}>
            {props.items.map((item, index) => {
              return (
                <Col key={index} md={3}>
                 <CategoryCard idx={index.toString()} item={item} />
                </Col>
                )
             })}           
           </Row>
         </Container>
    )
}
export default CategoryCards;