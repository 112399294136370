import React from "react";
import { Navbar, Container, Button } from "react-bootstrap";

const AdminNavbar = () => {
  return (
    <>
      <Navbar className={"navbar-absolute"} expand="lg">

      <Container fluid>
            <div className="navbar-wrapper">
              <div className="navbar-minimize d-inline">
                <Button
                  className="minimize-sidebar btn-just-icon"
                  color="link"
                  id="tooltip209599"
                  
                >
                  <i className="tim-icons icon-align-center visible-on-sidebar-regular" />
                  <i className="tim-icons icon-bullet-list-67 visible-on-sidebar-mini" />
                </Button>
              </div>
            </div>
      </Container>
      </Navbar>
    </>
  );
};

export default AdminNavbar;
