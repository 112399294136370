import React from "react";
import { Nav } from "react-bootstrap";

const Sidebar = () => {
  return (
    <div id="sidebar">
     <h2>DASHBOARD</h2>
     <div className="sidebar-wrapper">
      <Nav defaultActiveKey="/home" className="flex-column">
        <span className="sidebar-mini-icon">
          <Nav.Link href="/home">Active</Nav.Link>
        </span>
        <Nav.Link eventKey="link-1">Link</Nav.Link>
        <Nav.Link eventKey="link-2">Link</Nav.Link>
        <Nav.Link eventKey="disabled" disabled>
          Disabled
        </Nav.Link>
      </Nav>
      </div>
    </div>
  );
};

export default Sidebar;
