import React from "react";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Container from 'react-bootstrap/Container';

const NavBreadcrumb = () => {
  
  
  return (
    <Container>
      
      <Breadcrumb >
        <Breadcrumb.Item href="#" active>Home</Breadcrumb.Item>
        <Breadcrumb.Item href="/" >Back</Breadcrumb.Item>
      </Breadcrumb>
      </Container>
  );
};

export default NavBreadcrumb;
