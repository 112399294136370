import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Card } from "react-bootstrap";


const styles = {
  card: {
    minWidth: "20%",
    flexGrow: 0,
    margin: "4px",
  },
  cardTitle: {
    color: "#000",
  },
  cardImage: {
    objectFit: "cover",
    maxHeight: "140px",
    minHeight: "140px"
  },
  link: {
    textDecoration: "none",
    fontSize: ".5em",
    color: "#6D11B0",
    fontWeight: 700,
  },
};
function simulateNetworkRequest() {
  return new Promise((resolve) => setTimeout(resolve, 2000));
}

function CategoryCard(props) {
  const [isLoading, setLoading] = useState(false);

  const handleClick = () => {
    setLoading(true);
  };
  useEffect(() => {
    if (isLoading) {
      simulateNetworkRequest().then(() => {
        setLoading(false);
      });
    }
  }, [isLoading]);

  return (
    <>
      <Link
        style={styles.link}
        to={{
          pathname: `/category/${props.item.slug}`,
        }}
        state={{
          bannerUrl: props.item.banner.url,
          slug: props.item.slug,
          description: props.item.description.json,
          title: props.item.title,
          subTitle: props.item.subtitle,
          price: props.item.price
        }}
      >
        <Card key={props.idx} style={styles.card}>
          <Card.Img
            variant="top"
            src={props.item.image.url}
            style={styles.cardImage}
          />
          <Card.Body>
            <Card.Title style={styles.cardTitle}>{props.item.title}</Card.Title>
            <Card.Text style={{ fontSize: "1.05em" }}>
              {props.item.subtitle}
            </Card.Text>
            <Card.Subtitle>{props.item.callToAction}</Card.Subtitle>
          </Card.Body>

        </Card>
      </Link>

    </>
  );
}

export default CategoryCard;
