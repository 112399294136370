import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Container } from "react-bootstrap";
import { useAppContext } from "../../utils/ContextLib";
import { useNavigate } from "react-router-dom";
import { Auth } from 'aws-amplify';



function Login() {
  const { userHasAuthenticated } = useAppContext();
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  const [errorMessages, setErrorMessages] = useState({});
  const navigate = useNavigate();


  function validateForm() {
    return (username != null ? (username.length > 0 && password.length > 0) : "");
  }

  // Generate JSX code for error message
  const renderErrorMessage = (name) =>
    name === errorMessages.name && (
      <div className="error">{errorMessages.message}</div>
    );

  async function signIn() {

    try {
      Auth.signIn({ username: username, password: password })
        .then((user) => {
          console.log('successfully signed in', user)
          navigate("/dashboard");
          //window.analytics.identify(user.Auth)
          //window.analytics.page("category", "category listing");
          window.analytics.identify(user.attributes.sub, {
            username: user.username,
            email: user.attributes.email
          })
          userHasAuthenticated(true)
        })

    } catch (err) {
      setErrorMessages({ err })
    }

  }

  function confirmSignIn() {

    Auth.confirmSignIn(username)
      .then(() => console.log('successfully confirmed signed in'))
      .catch((err) => console.log(`Error confirming sign up - ${err}`))
  }
  const handleSubmit = (event) => {
    //Prevent page reload
    event.preventDefault();

    signIn();
    confirmSignIn()
    event.target.reset();
  };


  return (
    <Container>
      <div className="Login">
        <Form onSubmit={handleSubmit}>
          <Form.Group size="lg" controlId="username">
            <Form.Label>Username</Form.Label>
            <Form.Control
              autoFocus
              type="input"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            {renderErrorMessage("username")}
          </Form.Group>
          <Form.Group size="lg" controlId="password">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            {renderErrorMessage("password")}
          </Form.Group>
          <Button block size="lg" type="submit" disabled={!validateForm()}>
            Login
          </Button>
        </Form>
      </div>
    </Container>
  );
}

export default Login;
