import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';

import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  ApolloProvider
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { Amplify, Auth } from 'aws-amplify';
import awsconfig from '../src/aws-exports';
Amplify.configure(awsconfig);

const SERVER_URL = process.env.REACT_APP_SERVER_URL;
const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;

const httpLink = createHttpLink({
  uri: SERVER_URL,
});
const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem("token");
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      "Content-Type": "application/json",
      // Authenticate the request
      Authorization: "Bearer " + CLIENT_ID,
    },
  };
});


const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});


ReactDOM.render(
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>  
  ,
  document.getElementById('root')
);

