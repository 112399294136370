import React, {useState} from "react";
import { Container, Card, Button, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const styles = {
    imageStyle: {
        backgroundColor: "#f9f8f4",
        backgroundSize: "cover",
        border: "0",
        color: "#9c9795",
        maxHeight: "100%",
        minHeight: "10rem",
    },
    cardStyle: {
        marginTop: "25px",
        width: "100%",
        minWidth: "25%",
        flexGrow: 0,
    },
};

const ProductCard = (props) => {
    const item = props.item;
    const [cart, setCart] = useState([]);
    const [total, setTotal] = useState(0);
  
    function add(product) {
        setCart(current => [...current, product.name]);
        setTotal(current => current + product.price);
    }
  
  
    return (
        <>
            <Card style={styles.cardStyle} id={item.productId}>
                <Link
                    style={styles.link}
                    to={{
                        pathname: `/product/${item.slug}/${item.productId}`,
                    }}
                    state={{
                        slug: item.slug,
                        title: item.title,
                        subTitle: item.subtitle,
                        url: item.mediaImage.url,
                        body: item.body.json,
                        pid: item.productId,
                        price: item.price,
                        publishDate: item.publishDate
                    }}
                >
                    <Card.Img
                        style={styles.imageStyle}
                        variant="top"
                        src={item.mediaImage.url}
                    />
                    <Card.Body>
                        <Card.Title>{item.title}</Card.Title>
                        <Card.Text>{item.mediaImage.description}</Card.Text>
                     
                    </Card.Body>
                </Link>
            </Card>

        </>
    )
}

export default ProductCard;