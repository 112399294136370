import React from 'react'
import { Container, Row, Button } from 'react-bootstrap'


const styles = {
    blockContainer: {
        paddingBottom: "4rem",
        paddingTop: "4rem",
        paddingLeft: "2rem",
        background: "#FFFF",
        borderRadius: "0.3em"
    },
    item: {
        maxWidth: "60rem",
    }
}

const BlockContainer = () => {
    return (

        <div style={styles.blockContainer}>
            <div style={styles.item}>
                Minecraft Skins enables you to change how your player looks to others in the Minecraft world.
                Customize any of these Minecraft skins with a Minecraft skin editor and deploy in no time at all.
            </div>
            <Button variant="secondary">Read More</Button>
        </div>


    )
}

export default BlockContainer;