import JumboBanner from "./components/Banner";
import Breadcrumb from "./components/NavBreadcrumb";
import ProductCards from "./components/ProductCards";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS } from "@contentful/rich-text-types";
import { useLocation } from "react-router-dom";
import { Button, Container, Row } from "react-bootstrap";

import { useQuery, gql } from "@apollo/client";
import { FullStoryAPI } from 'react-fullstory';

const Category = (props) => {

  const styles = {
    category: {
      display: "flex",
    }
  }

  const location = useLocation();
  const catData = location.state;
  const handleClick = () => {
    // do stuff
    FullStoryAPI('event', 'Subscribed', {
      uid_str: '750948353',
      plan_name_str: 'Professional',
      plan_price_real: 299,
      plan_users_int: 10,
      days_in_trial_int: 42,
      feature_packs: ['MAPS', 'DEV', 'DATA'],
    });
  }
  const renderDocument = (document) => {
    const Text = ({ children }) => <p>{children}</p>;

    const options = {
      renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
      },
      renderText: (text) =>
        text.split("\n").flatMap((text, i) => [i > 0 && <br />, text]),
    };

    return documentToReactComponents(document, options);
  };

  const CATEGORY_ITEMS = gql`
    query GetContentItems($categorySlug: String!) {
      contentItemCollection(where: { categorySlug: $categorySlug }, limit: 10) {
        items {
          title
          subtitle
          price
          publishDate
          mediaImage {
            width
            height
            description
            url
          }
          slug
          productId
          body {
            json
          }
        }
      }
    }
  `;
  function CategoryItems({ categorySlug }) {
    const { loading, error, data } = useQuery(CATEGORY_ITEMS, {
      variables: { categorySlug },
    });

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error :(</p>;

    return <ProductCards items={data.contentItemCollection.items} />;
  }

  return (

    <Container>
      <Row>
        <Breadcrumb />
        <JumboBanner bannerUrl={catData.bannerUrl} />
        <div className="col-12 article ">
          <span className="title">{catData.title}</span>
          <p>{catData.subTitle}</p>
          {renderDocument(catData.description)}
          <div className="d-grid gap-2">
            <Button
              variant="secondary"
              size="lg"
              onClick={handleClick}
            >
              Get More Information
            </Button>
          </div>
        </div>
        <CategoryItems categorySlug={catData.slug} />
      </Row>
    </Container>
  );
};

export default Category;
