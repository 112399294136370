import React from 'react';
import { Container } from 'react-bootstrap';

function About() {
    return(
        <Container fluid className="App-header">
          <span>About us</span>
        </Container>
    )
}

export default About;