import { Container } from "react-bootstrap";
import CategoryCards from "./components/CategoryCards";
import JumboBanner from "./components/Banner";
import BlockContainer from "./components/BlockContainer";

import {
  useQuery,
  gql,
} from "@apollo/client";



const CATEGORIES = gql`
  query GetCategories {
    categoryCollection {
      items {
        title
        subtitle 
        slug
        callToAction
        image {
         title
         url
         width
         height
        }
        description{
          json
        }
        banner{
          width 
          height
          description
          url
        }
      }
    }
  }
`;
function TopCategories() {
  const { loading, error, data } = useQuery(CATEGORIES);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  return <CategoryCards items={data.categoryCollection.items} />

}

function Home() {

  return (
    <Container fluid className="App-header">
      <Container>
        <JumboBanner
          title="Hi, everyone!"
          message="This is my collection of Roblox designs, Minecraft skins and lego reviews!"
          label="Check it out!"
          type="container"
        />
      </Container>
      <TopCategories />
      <Container style={{ paddingTop: "1.3em"}} >
        <BlockContainer />
      </Container>
    </Container>
  );
}

export default Home;
