import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import Form from 'react-bootstrap/Form';
import { Container } from "react-bootstrap";
import Button from "react-bootstrap/Button";



const SignUp = () => {
    const [email, setEmail] = useState("");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [errorMessages, setErrorMessages] = useState({});
    const [phoneNumber, setPhoneNumber] = useState("");
    function validateForm() {
        return (email != null ? (email.length > 0 && password.length > 0) : "");
    }

    // Generate JSX code for error message
    const renderErrorMessage = (name) =>
        name === errorMessages.name && (
            <div className="error">{errorMessages.message}</div>
        );

    async function createAccount(event) {

        event.preventDefault();

        try {
            await Auth.signUp({
                username: username,
                password: password,
                attributes: {
                    email,          // optional
                }
            }).then((user) => {
                // call the Segment Track call for SignUp
                window.analytics.track("user signup", {
                    plan: "entry level annual",
                    accountType: "basic"
                })
            });


        } catch (error) {
            console.log('error signing up:', error);
        }
    }
    return (
        <Container>
            <div className="SignUp">
                <Form onSubmit={createAccount}>
                    <Form.Group size="lg" controlId="username">
                        <Form.Label>Username</Form.Label>
                        <Form.Control
                            autoFocus
                            type="input"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                        />
                        {renderErrorMessage("username")}
                    </Form.Group>
                    <Form.Group size="lg" controlId="email">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                            autoFocus
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        {renderErrorMessage("email")}
                    </Form.Group>
                    <Form.Group size="lg" controlId="password">
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        {renderErrorMessage("password")}
                    </Form.Group>
                    <Button block size="lg" type="submit" disabled={!validateForm()}>
                        Sign Up
                    </Button>
                </Form>
            </div>
        </Container>
    )
}

export default SignUp;